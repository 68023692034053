import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './LandingPage.css';
import logo from './logo.png'; // Ensure you have a logo file in the same directory

function LandingPage() {
  const [formData, setFormData] = useState({
    email: '',
    projectDetails: '',
    additionalInfo: '',
  });

  const formRef = useRef();
  const [btnText, setBtnText] = useState('Submit');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnText('Sending...');

    const serviceID = 'default_service';
    const templateID = 'template_fg2lh9i';
    const userID = 'AykH4oxhxUO6qmsIO'; // Ensure this is the correct user ID (public key)

    emailjs.sendForm(serviceID, templateID, formRef.current, userID)
      .then(() => {
        setBtnText('Submit');
        alert('Sent!');
        // Optionally reset the form
        setFormData({
          email: '',
          projectDetails: '',
          additionalInfo: '',
        });
      }, (err) => {
        setBtnText('Submit');
        alert(JSON.stringify(err));
      });
  };

  return (
    <div className="container">
      <nav className="navbar">
        <img src={logo} alt="BroCode Software Logo" className="logo" />
        <h1 className="title">Bro Code Software</h1>
      </nav>
      <main className="main-content">
        <h2 className="subtitle">Bring your vision to life!</h2>
        <p className="description">
          Transform your ideas into software solutions
        </p>
        <div className="form-container">
          <h3>Tell Us About Your Project</h3>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Project Details:</label>
              <textarea
                name="projectDetails"
                value={formData.projectDetails}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Additional Information:</label>
              <textarea
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="submit-button">{btnText}</button>
          </form>
        </div>
      </main>
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Bro Code Software. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default LandingPage;
